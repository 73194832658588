import React, { useEffect, useState } from "react";
import { graphql } from "gatsby";
import "../../src/styles/reset.css";
import "../../src/styles/project.css";
import Layout from "../components/layout";
import { Parallax } from "react-scroll-parallax";
import { ParallaxProvider } from "react-scroll-parallax";

const IndexPage = ({ data }) => {
  const [isMobile, setIsMobile] = useState(false);
  let thisYear = new Date().getFullYear();

  // Check if the width is mobile -- if so, disable parallax because iOS makes it bad
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Layout>
      <ParallaxProvider>
        <div className="parallax-wrapper">
          <section className="column">
            <h1>Quest Machina</h1>

            <div className="planet-left">
              <Parallax speed={isMobile ? 0 : -15}>
                <img src="/art/desert-planet.webp" />
              </Parallax>
            </div>

            <div className="feature">
              <p>
                Quest Machina is a single-player game that uses generative AI to
                create a unique adventure on each playthrough. Create your
                character, add prompts to influence the generation, and explore
                a world made just for you.
              </p>
            </div>

            <a href="https://store.steampowered.com/app/2424330/Quest_Machina/">
              <div className="feature coming-soon">
                <h2>Coming soon to Steam! Add to your wishlist</h2>
                <div className="flex-combo">
                  <div className="img-wrapper">
                    <img
                      src="/art/explorelayout.jpg"
                      alt="A screenshot of a Quest Machina world, showing the player exploring the Celestial Gardens."
                    />
                  </div>
                  <div className="img-wrapper">
                    <img
                      src="/art/map.jpg"
                      alt="A screenshot of a Quest Machina menu, showing locations the player can travel to."
                    />
                  </div>
                </div>
              </div>
            </a>

            <div className="feature">
              <h2>Discover a unique star system</h2>
              <p>
                Create your character and visit planets rocked by conflict. Each
                game has a unique storyline and final boss, which can be
                influenced by your inputs or created entirely by AI.
              </p>
            </div>

            <div className="planet-right">
              <Parallax speed={isMobile ? 0 : -20}>
                <img src="/art/fire-planet.webp" />
              </Parallax>
            </div>

            <div className="feature">
              <h2>Build your deck</h2>
              <p>
                Craft a deck to face off against space pirates and hostile
                creatures in card-based combat. Strategize with Hold effects and
                Play effects to deal damage while protecting yourself from the
                next attack.
              </p>
            </div>

            <div className="planet-left">
              <Parallax speed={isMobile ? 0 : -25}>
                <img src="/art/haze-planet.webp" />
              </Parallax>
            </div>

            <div className="feature">
              <h2>Load up with gear</h2>
              <p>
                Buy, loot, and steal gear to increase your stats and help you
                overcome challenges across the star system. Align your stats
                with your deck to power up your cards.
              </p>
            </div>

            <div className="feature">
              <h2>Be a hero or a villain</h2>
              <p>
                Save the villager or steal their gear? It's your choice, but
                prepare for the consequences. Each game has multiple endings;
                the one you get is selected by the mark you left on the world.
              </p>
            </div>

            <div className="feature">
              <h2>Share your world</h2>
              <p>
                Every world in Quest Machina has a unique ID. Send it to a
                friend and they can import your world into a save slot.
              </p>
            </div>

            <div className="feature copyright">
              <p>
                © {thisYear} Kintech |{" "}
                <a href="mailto:support@questmachina.com">Contact by email</a>
              </p>
            </div>
          </section>
        </div>
      </ParallaxProvider>
    </Layout>
  );
};

// export const query = graphql`
//   {
//     allMainCsv {
//       edges {
//         node {
//           filename
//           title
//           date
//         }
//       }
//     }
//   }
// `;

export default IndexPage;
